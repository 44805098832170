import { isSameMonth } from 'date-fns'
import * as Notifications from 'expo-notifications'
import { useNavigation } from 'expo-router'
import { useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { Divider, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import useRefetchOnFocus from '../../hooks/useRefetchOnFocus'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import Page from '../shared/Page'
import DashboardEventCard from './DashboardEventCard'
import DashboardTimeline from './DashboardTimeline'

const DashboardTab = () => {
  const { styles } = useStyles(stylesheet)

  Notifications.setBadgeCountAsync(-1)

  const navigation = useNavigation()

  const currentOrganization = useAppStore.use.currentOrganization().organization

  const currentUserQuery = trpc.user.current.useQuery(
    {
      organizationId: currentOrganization?.id,
    },
    {
      enabled: !!currentOrganization,
    }
  )

  useRefetchOnFocus(currentUserQuery.refetch)

  const currentUser = currentUserQuery.data

  const events = trpc.dashboard.events.useQuery(
    {
      organizationId: currentOrganization?.id || '',
    },
    {
      enabled: !!currentOrganization,
    }
  )

  useRefetchOnFocus(events.refetch)

  const eventsByDayAndFoster = events.data

  useEffect(() => {
    if (!currentOrganization) {
      return
    }

    navigation.setOptions({
      title: currentOrganization.name,
    })
  }, [navigation, currentOrganization])

  const onRefresh = useCallback(async () => {
    await events.refetch()
  }, [events])

  if (!currentUser) {
    return null
  }

  if (!eventsByDayAndFoster) {
    return null
  }

  return (
    <Page
      onRefresh={onRefresh}
      // contentContainerStyle={styles.container}
      viewId="dashboard-scroll-view"
    >
      {eventsByDayAndFoster?.map((day, dayIndex) => (
        <View key={`${day.dueDate}`} style={styles.root}>
          {dayIndex > 0 &&
            !isSameMonth(
              day.dueDate,
              eventsByDayAndFoster[dayIndex - 1].dueDate
            ) && (
              <View style={styles.rowContainer}>
                {/* <Divider style={{ width: '30%' }} /> */}
                <Text>
                  {day.dueDate.toLocaleDateString('en-US', {
                    month: 'long',
                  })}
                </Text>
                <Divider style={styles.monthDivider} />
              </View>
            )}
          <View style={styles.eventsContainer}>
            <DashboardTimeline day={day} />

            <View style={styles.cardsContainer}>
              {dayIndex === 0 && !day.fosters.length && (
                <View style={styles.zeroStateToday}>
                  <Text style={styles.zeroStateTodayText}>
                    All caught up for today! 🎉
                  </Text>
                </View>
              )}
              {day.fosters.map((foster) => (
                <DashboardEventCard
                  foster={foster}
                  isOverdue={day.isOverdue}
                  key={`${foster.fosterId}-${day.dueDate.toISOString()}`}
                />
              ))}
            </View>
          </View>
        </View>
      ))}
    </Page>
  )
}

export default DashboardTab

const stylesheet = createStyleSheet((theme) => {
  return {
    cardsContainer: {
      flex: 1,
      rowGap: 8,
    },
    eventsContainer: {
      flexDirection: 'row',
      flex: 1,
      marginBottom: 20,
    },
    monthDivider: {
      backgroundColor: theme.colors.secondary,
      flex: 1,
    },
    root: {
      flex: 1,
    },
    rowContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
      gap: 12,
      justifyContent: 'flex-start',
      marginBottom: 12,
    },
    zeroStateToday: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
    },
    zeroStateTodayText: {
      fontSize: 16,
      marginLeft: 32,
    },
  }
})
